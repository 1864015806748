.wordle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid rgba(var(--text-color-rgb), 0.1);
}

.title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--text-color);
  letter-spacing: 1px;
}

@media (max-width: 400px) {
  .title {
    font-size: 1.2rem;
  }
}

.wordle-grid {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.wordle-row {
  display: flex;
  gap: 4px;
}

.wordle-cell {
  width: 50px;
  height: 50px;
  border: 2px solid #3a3a3c;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 2px;
  text-transform: uppercase;
}

.wordle-cell.correct {
  background-color: #6aaa64;
  border-color: #6aaa64;
  color: white;
}

.wordle-cell.present {
  background-color: #c9b458;
  border-color: #c9b458;
  color: white;
}

.wordle-cell.absent {
  background-color: #787c7e;
  border-color: #787c7e;
  color: white;
}

[data-theme='dark'] .wordle-cell {
  border-color: #565758;
  color: white;
}

[data-theme='light'] .wordle-cell {
  border-color: #d3d6da;
  color: black;
}

.keyboard {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 20px;
  width: 100%;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.keyboard-key {
  min-width: 30px;
  height: 45px;
  border: none;
  border-radius: 6px;
  background-color: var(--text-color);
  color: var(--bg-color);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  transition: all 0.15s ease;
}

.keyboard-key:hover:not(:disabled) {
  opacity: 0.8;
}

.keyboard-key.enter-key,
.keyboard-key.backspace-key {
  min-width: 60px;
}

@media (max-width: 500px) {
  .wordle-cell {
    width: 45px;
    height: 45px;
    font-size: 1.5rem;
  }

  .keyboard-key {
    min-width: 28px;
    height: 40px;
    font-size: 0.9rem;
  }

  .keyboard-key.enter-key,
  .keyboard-key.backspace-key {
    min-width: 50px;
  }
}

.stats-preview {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.stats-preview:hover {
  background-color: rgba(var(--text-color-rgb), 0.1);
}

.streak-icon {
  font-size: 1.2rem;
}

.streak-count {
  font-weight: bold;
  font-size: 1.2rem;
}

.stats-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.stats-modal {
  background: var(--bg-color);
  padding: 2rem;
  border-radius: 12px;
  min-width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.stats-modal h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-item {
  text-align: center;
  padding: 1rem;
  background: rgba(var(--text-color-rgb), 0.05);
  border-radius: 8px;
}

.stat-number {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0.3rem;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.8;
}

.close-stats {
  display: block;
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 6px;
  background: var(--text-color);
  color: var(--bg-color);
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.close-stats:hover {
  opacity: 0.9;
} 